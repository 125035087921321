<template>
  <button
    :id="elementId"
    v-tooltip="
      tooltip?.length > 0
        ? {
            content: tooltip,
            html: true
          }
        : {}
    "
    :class="[disabled ? 'button button--disabled' : getClass(background)]"
    :disabled="disabled"
  >
    <slot />
  </button>
</template>

<script setup>
defineProps({
  elementId: {
    type: String,
    required: true
  },
  background: {
    type: String,
    default: 'button'
  },
  disabled: {
    type: Boolean,
    default: false
  },
  tooltip: {
    type: String,
    default: ''
  }
});
const getClass = (value) => value;
</script>
